document.addEventListener('DOMContentLoaded', () => {
// !-----------------------------------------------------------------!
    // Открытие менюшки
    function showMenu() {
        const btn = document.querySelector(".burger_js"),
            menu = document.querySelector(".header_links_js");

        btn.addEventListener("click", () => {
            menu.classList.toggle("-active")
        });

    }

    showMenu();

// !-----------------------------------------------------------------!

//    Слайдер фотоотчета
    try {
        const photoOtchet1 = new Carousel(document.querySelector(".photo_otchet_1_js"), {
            Dots: false
        });
    } catch (e) {
        console.log("Похоже что слайдера на странице нет")
    }

    try {
        const photoOtchet2 = new Carousel(document.querySelector(".photo_otchet_2_js"), {
            Dots: false
        });
    } catch (e) {
        console.log("Похоже что слайдера на странице нет")
    }

//    СЛАЙДЕР ДЛЯ СТРАНИЦЫ ТОВАРА
    try {
        const tp_slider1 = new Carousel(document.querySelector(".tp_slider1"), {
            Dots: false
        });
        const tp_slider2 = new Carousel(document.querySelector(".tp_slider2"), {
            Sync: {
                target: tp_slider1,
            },
            Dots: false,
            Navigation: false,
            infinite: false,
            center: true,
            slidesPerPage: 1,
        });
    } catch (e) {
        console.log("Похоже что слайдера на странице нет")
    }
//    Выключить автофокус у фенсибокс
    Fancybox.bind("[data-fancybox]", {
        autoFocus: false
    });

// !-----------------------------------------------------------------!

//    QUIZ

    class Quiz {
        _quiz;
        _quizWrap;
        _radInp;
        _btnBack;
        _iKey;
        _area;
        _btnArea;

        constructor(quizID) {
            this._quiz = document.getElementById(quizID);
            if (this._quiz === null) {
                return
            }
            this._quizWrap = this._quiz.querySelectorAll(".quiz_wrap_js");
            this._area = this._quiz.querySelector(".area_js");
            this._btnArea = this._quiz.querySelector(".area_js_btn");
            this._radInp = this._quiz.querySelectorAll("input[type=radio]");
            this._btnBack = this._quiz.querySelectorAll(".quiz_back_js");
            this._iKey = 0;

            this.addListener();
            this.addListenerToBtnBack();
        }

        addListener() {

            this._radInp.forEach(elem => {
                elem.addEventListener("change", () => {
                    this.next();
                })
            });

            this._btnArea.addEventListener("click", () => {
                let area_error = this._quiz.querySelector(".area_error");
                if (this._area.value.trim() === '' || this._area.value.trim() === ' ') {
                    area_error.style.display = "block";
                } else {
                    this.next()
                }
            })
        }

        addListenerToBtnBack() {
            this._btnBack.forEach(elem => {
                elem.addEventListener("click", () => {
                    this.prev();
                })
            });
        }

        next() {
            this._quizWrap[this._iKey].style.display = "none";
            this._quizWrap[this._iKey + 1].style.display = "block";
            this._iKey++;
        }

        prev() {
            this._quizWrap[this._iKey].style.display = "none";
            this._quizWrap[this._iKey - 1].style.display = "block";
            this._iKey--;
            this._radInp.forEach(elem => {
                if (elem.name === `quiz_rad_${this._iKey}`) {
                    elem.checked = false;
                }
            });
        }
    }

    let quiz1 = new Quiz("quiz-1");

// !-----------------------------------------------------------------!

//    ПОКАЗАТЬ ДОП ЭЛЕМЕНТЫ
//     Логика для всех блоков данного типа

    function showMoreLogic(countVisible, classElements, classBtn, classParent) {
        let elements, btn, parent, parentHeight = 0;

        try {
            parent = document.querySelector(`.${classParent}`);
            elements = document.querySelectorAll(`.${classElements}`);
            btn = document.querySelector(`.${classBtn}`);

        } catch (e) {
            console.log("Функция showMoreLogic. Нет элементов на странице. " + e);
            return
        }

        if (parent === null || elements === null || btn === null) {
            return
        }

        // скрыть лишние элементы
        elements.forEach((elem, i) => {
            if (i >= parseInt(countVisible)) {
                elem.classList.add('-hide')
            }
        });

        //    Показать все элементы при клике
        btn.addEventListener("click", () => {
            parentHeight = parent.getBoundingClientRect().height;
            // Меняем текст на кнопке
            if (elements[countVisible].classList.contains("-hide")) {
                btn.textContent = "Скрыть"
            } else {
                btn.textContent = "Показать все ↓";
                // чтобы экран пользователь оставался на том же месте где и был
                parentHeight = parent.getBoundingClientRect().height - parentHeight;
                window.scrollBy(0, -parentHeight)
            }
            // Скрываем элементы
            elements.forEach((elem, i) => {
                if (i >= parseInt(countVisible)) {
                    elem.classList.toggle('-hide')
                }
            });
        })
    }

    showMoreLogic(6, "drenazh_elem_js", "drenazh_btn_js", "drenazh_system_js");
    showMoreLogic(4, "material_elem_js", "material_btn_js", "material_js");
// !-----------------------------------------------------------------!

    //Логика для табов и таблиц

    function myTabs(classWrapElements, classBtn, classContent) {
        let elements;
        try {
            elements = document.querySelectorAll(`.${classWrapElements}`);
        } catch (e) {
            console.log("Функция myTabs. Нет элементов на странице. " + e);
            return
        }
        if (elements === null) {
            return
        }
        elements.forEach(elem => {
            let btn = elem.querySelector(`.${classBtn}`),
                content = elem.querySelector(`.${classContent}`);

            elem.addEventListener("click", function () {
                btn.classList.toggle("-active");
                content.classList.toggle("-hide");

                // Доп логика для блока вопросы и ответы (жесткая привязка к классу)
                if (classWrapElements === "question_elem_js") {
                    elem.classList.toggle("-no_height")
                }

            })

        })
    }

    myTabs("price_table_js", "price_table_drop_js", "price_table_content_js");
    myTabs("question_elem_js", "question_btn_js", "question_content_js");


// !-----------------------------------------------------------------!

    // РАЗВЕРНУТЬ ТЕКСТ В БЛОКЕ МАТЕРИАЛЫ
    function materialShow() {
        let elements;
        try {
            elements = document.querySelectorAll(".material_show_js");
        } catch (e) {
            console.log("Ошибка в materialShow. Нет этих элементов" + e);
            return
        }
        if (elements === null) {
            return
        }
        elements.forEach(elem => {
            try {
                let btn = elem.querySelector(".material_showBtn_js"),
                    btnHide = elem.querySelector(".material_hideBtn_js"),
                    hideContent = elem.querySelector(".material_hideContent_js");

                btn.addEventListener("click", () => {
                    btn.classList.toggle("-hide");
                    btnHide.classList.toggle("-hide");
                    hideContent.classList.toggle("-hide")
                });
                btnHide.addEventListener("click", () => {
                    btn.classList.toggle("-hide");
                    btnHide.classList.toggle("-hide");
                    hideContent.classList.toggle("-hide")
                })
            } catch (e) {
                console.log("Не так много текста, чтобы разворачивать")
            }
        })
    }

    materialShow();


// !-----------------------------------------------------------------!

    // КАЛЬКУЛЯТОР

    class Calc {
        _calc;
        _data;
        _textBottom; // Текст над ценой
        _price;
        _dataInputs = []; // для хранения инпутов
        _insertInto; // Куда будем вставлять элемент
        _hiddenPrice; // скрытый элемент для того чтобы видеть в заявке цену

        constructor(insertInto, textBottom, data) {
            this._data = data.reverse();
            this._textBottom = textBottom;
            this._insertInto = insertInto;
            this.createCALC();

        }

        createCALC() {
            try {
                this._calc = document.querySelector(".calcTemplate").cloneNode(true);
            } catch (e) {
                console.log("калькулятора нет на странице" + e);
                return
            }
            // Скрытая цена, для того чтобы видеть в заявке
            this._hiddenPrice = this._calc.querySelector(".calc_price_js");
            // ПОСЛЕ какого элемента будет вставлять калькулятор
            let insert = document.querySelector(`.${this._insertInto}`);

            // Удаляю стиль, который скрывает калькулятор - шаблон
            this._calc.classList.remove("-hide");
            this._calc.classList.remove("calcTemplate");

            // Установил текст над ценой для каждого калькулятора
            this._calc.querySelector(".itog_title_js").textContent = this._textBottom;
            // Выявил HTML элемент в общую переменную класса
            this._price = this._calc.querySelector(".itog_price_js");

            // Перейдусь по всем данным которые дал в конструкторе, и на каждый объект буду создавать новый элемент калькулятора
            this._data.forEach((dataForOne, i) => {
                // Создаю дубликаты элементов при каждой итерации цикла, чтобы потом в него вставлять данные
                let elem = this._calc.querySelector(".calc_elem_js").cloneNode(true);

                // Удаляю элемент который был в шаблоне
                if (i === 0) {
                    this._calc.querySelector(".calc_elem_js").remove();
                }

                // Внутри клона, меняю нужные мне элементы
                let inp = elem.querySelector("input[type=range]"),
                    name = elem.querySelector(".calc_elem_name_js"),
                    subName = elem.querySelector(".calc_elem_subname_js"),
                    rangeValue = elem.querySelector(".range_value_js"),
                    rangeMin = elem.querySelector(".range_min_js"),
                    rangeMax = elem.querySelector(".range_max_js");


                // Поменял значения у элементов
                name.textContent = dataForOne.name; // Название слева
                subName.textContent = dataForOne.subName; // Что сделать слева мелкок
                rangeValue.textContent = dataForOne.min; // Текущее значение инпута
                rangeMin.textContent = dataForOne.min; // Минимальное значение инпута (тег "р"
                rangeMax.textContent = dataForOne.max; // Максимальное значение инпута (тег "р"

                // Изначально установил значения для инпутов
                inp.name = dataForOne.name;
                inp.min = dataForOne.min;
                inp.max = dataForOne.max;
                inp.step = dataForOne.step;
                inp.value = dataForOne.min;


                // Установил дата атрибут с ценой
                inp.dataset.price = dataForOne.price;
                // Установил дата атрибут, входит ли цена в стоимость или нет
                inp.dataset.start = dataForOne.startOtchet;
                // Добавил инпуты в массив, для дальнейшей работы с ними
                this._dataInputs.push(inp);


                // вставка каждого элемента внутрь калькулятора
                this._calc.prepend(elem);

                function changeValueAndPos() {
                    //Показываем сколько сейчас выбрано
                    rangeValue.textContent = inp.value;
                    // подвину табличку туда, где сейчас стоит инпут
                    rangeValue.style.left = `${100 / (inp.max - inp.min) * inp.value - (100 / (inp.max - inp.min) * inp.min)}%`
                }


                // Добавить слушателя на инпут, чтобы показывать текущее значение
                inp.addEventListener("click", changeValueAndPos);
                inp.addEventListener("mousemove", changeValueAndPos);
                inp.addEventListener("touchmove", changeValueAndPos);
                inp.addEventListener("touchend", changeValueAndPos);

            });
            // Добавить ВЕСЬ калькулятор на страницу
            insert.append(this._calc);

            this.raschet()

        }

        raschet() {
            let summ = 0, itog;
            let minusThis = 0;
            // Расчеты
            this._dataInputs.forEach(elem => {
                elem.addEventListener("change", () => {
                    summ = 0;
                    itog = calc() + " рублей";
                    this._price.textContent = itog;
                    this._hiddenPrice.value = itog;
                });
            });

            let calc = () => {
                this._dataInputs.forEach(elem => {
                    if (elem.dataset.start !== "null") {
                        // Для того чтобы убрать цену, которая уже включена в стоимость
                        minusThis = parseInt(elem.dataset.start) * parseInt(elem.dataset.price);
                        summ += Math.round(parseInt(elem.dataset.price) * elem.value - minusThis);
                    } else {
                        summ += parseInt(elem.dataset.price) * elem.value;
                    }

                });
                return summ;
            };
            this._price.textContent = calc() + " рублей";
            this._hiddenPrice.value = calc() + " рублей";
        }


    }

    /*
    * {
            name: "Количество соток", - наименование слева
            subName: "выберите площадь участка в сотках", ПОД наименованием слева
            min: 6, - минимальный показатель
            max: 50, - максиамльный показатель
            step: 1, - шаг изменения инпута
            price: 12300, - цена за 1 деление
            startOtchet: null - для того, чтобы включить в общую стоимость
            какую либо услугу (например с глубиной, во 2 и 3 калькуляторе)
        },
    */
    let calc = new Calc("calc1", "Итоговая стоимость дренажа участка:", [
        {
            name: "Количество соток",
            subName: "выберите площадь участка в сотках",
            min: 6,
            max: 50,
            step: 1,
            price: 12300,
            startOtchet: null
        },
        {
            name: "Глубина сборного колодца, м",
            subName: "выберите глубину сборного бетонного колодца. Если колодец не нужен, выберите 0.",
            min: 0,
            max: 6,
            step: 1,
            price: 7000,
            startOtchet: null
        },
        {
            name: "Оборудование колодца насосной станцией",
            subName: "Введите количество дренажных насосов. Если не требуются, введите 0.",
            min: 0,
            max: 2,
            step: 1,
            price: 7000,
            startOtchet: null
        }
    ]);
    let calc2 = new Calc("calc2", "Итоговая стоимость дренажа участка:", [
        {
            name: "Количество метров вокруг дома",
            subName: "выберите длину периметра дома с отступом от фундамента около 2 м",
            min: 20,
            max: 100,
            step: 1,
            price: 1800,
            startOtchet: null
        },
        {
            name: "Глубина фундамента, м",
            subName: "выберите глубину относительно грунта нижней точки фундамента",
            min: 1,
            max: 3,
            step: 0.1,
            price: 36000,
            startOtchet: 1
        },
        {
            name: "Количество ревизионных колодцев",
            subName: "Введите количество ревизионных колодцев. Ставятся по углам дома.",
            min: 0,
            max: 24,
            step: 1,
            price: 7500,
            startOtchet: null
        },
        {
            name: "Сборный колодец",
            subName: "Введите глубину сборного колодца. Если cборный колодец не нужен, введите 0",
            min: 0,
            max: 7,
            step: 1,
            price: 7500,
            startOtchet: null
        }, {
            name: "Оборудование сборного колодца насосными станциями",
            subName: "Введите количество дренажных насосов. Если не нужны, введите 0",
            min: 0,
            max: 2,
            step: 1,
            price: 6000,
            startOtchet: null
        }
    ]);
    let calc3 = new Calc("calc3", "Итоговая стоимость дренажа участка:", [
        {
            name: "Количество метров вокруг дома",
            subName: "выберите длину периметра дома без отступа от фундамента ",
            min: 20,
            max: 100,
            step: 1,
            price: 2800,
            startOtchet: null
        },
        {
            name: "Глубина фундамента, м",
            subName: "выберите глубину относительно грунта нижней точки фундамента",
            min: 1,
            max: 3,
            step: 0.1,
            price: 56000,
            startOtchet: 1
        },
        {
            name: "Количество ревизионных колодцев",
            subName: "Введите количество ревизионных колодцев. Ставятся по углам дома.",
            min: 0,
            max: 24,
            step: 1,
            price: 7500,
            startOtchet: null
        },
        {
            name: "Сборный колодец",
            subName: "Введите глубину сборного колодца. Если cборный колодец не нужен, введите 0",
            min: 0,
            max: 7,
            step: 1,
            price: 7000,
            startOtchet: null
        },
        {
            name: "Оборудование сборного колодца насосными станциями",
            subName: "Введите количество дренажных насосов. Если не нужны, введите 0",
            min: 0,
            max: 2,
            step: 1,
            price: 7000,
            startOtchet: null
        }
    ]);


// !-----------------------------------------------------------------!

//    Отправка на сервер
    //Поиск моих форм по странице
    function findMyForms() {
        let forms = document.querySelectorAll(".my-forms");
        forms.forEach(form => {
            // Для проверки данных в переменно $_POST
            // form.method = "POST";
            // form.action = "/fn";
            form.addEventListener("submit", (e) => {

                e.preventDefault();
                req(e, form);
            });
        });
    }

    findMyForms();

    //Получаем ответ от сервера
    function req(e, actionElem) {
        let formData = new FormData(actionElem);
        //Проверка ЕСТЬ ЛИ ФАЙЛ
        if (formData.has("userfile")) {
            // проверка пустой ли файл
            if (formData.get("userfile").name !== "" || formData.get("userfile").type !== "application/octet-stream") {
                //Проверка на тип файла. Проходит по перечню и выдает false если найдет совпадение
                if (formData.get("userfile").type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                    formData.get("userfile").type !== "application/msword" &&
                    formData.get("userfile").type !== "application/pdf" &&
                    formData.get("userfile").type !== "image/jpeg" &&
                    formData.get("userfile").type !== "image/png") {
                    return showPopup("format");
                    //Проверка на размер файла (до 10 Мб)10485760
                } else if (formData.get("userfile").size > 10485760) {
                    return showPopup("bigsize");
                }
                //Если файла не загрузили то удаляем это поле
            } else {
                formData.delete("userfile");
            }
        }
        //Проверка МАСКИ ТЕЛЕФОНА +7(xxx) ......
        if (
            formData.get("phone").length !== 17 ||
            formData.get("phone")[0] !== "+" ||
            formData.get("phone")[1] !== "7" ||
            formData.get("phone")[2] !== "(" ||
            formData.get('phone')[3] === '8' ||
            formData.get('phone')[6] !== ')' ||
            formData.get('phone')[16] === '_'
        ) {
            return showPopup("wrong");
        }

        //Переименовать инпуты
        if (formData.has('quiz_rad_0') && formData.has('quiz_rad_1') && formData.has('quiz_rad_2') && formData.has("quiz_rad_4")) {
            let quiz_rad_0 = formData.get('quiz_rad_0'),
                quiz_rad_1 = formData.get('quiz_rad_1'),
                quiz_rad_2 = formData.get('quiz_rad_2'),
                area = formData.get('area'),
                quiz_rad_4 = formData.get('quiz_rad_4');

            formData.delete('quiz_rad_0');
            formData.delete('quiz_rad_1');
            formData.delete('quiz_rad_2');
            formData.delete('area');
            formData.delete('quiz_rad_4');

            formData.append('Какую проблему необходимо решить?', quiz_rad_0);
            formData.append('Какой уровень грунтовых вод на вашем участке?', quiz_rad_1);
            formData.append('Укажите тип грунта на участке?', quiz_rad_2);
            formData.append('Какая площадь вашего участка в сотках?', area);
            formData.append('Куда будем отводить излишек влаги с участка?', quiz_rad_4);
        }
        // formData.delete("politica");
        getData("/fn", formData)
            .then(data => showPopup(data, actionElem));
    }

    // Запрос к серверу
    async function getData(url, data) {
        const res = await fetch(`${url}`, {
            method: "POST",
            body: data
        });
        if (!res.ok) {
            console.log("Error: " + res.status);
        }
        return await res.text();
    }

    //Всплывашка с подтверждением / отказом
    function showPopup(data, form) {
        let div = document.createElement("div"),
            p = document.createElement("p"),
            span = document.createElement("span"),
            closeBtn = document.createElement("b");
        //close btn
        closeBtn.addEventListener("click", () => {
            div.remove();
        });
        //Check server - response
        if (data === "success") {
            // p.innerHTML = "Спасибо за заявку!";
            // span.innerHTML = "Мы свяжемся с вами в самое ближайшее время";
            // div.classList.remove("my-popup-error");
            // div.classList.add("my-popup-done");
            // //  Отчистим форму
            // clearMyForm(form);
            document.location.href = "/thanks";
            return;
        } else if (data === "wrong") {
            p.innerHTML = "Неверно ввели номер";
            span.innerHTML = "Пожалуйста, проверьте верно ли вы ввели номер. Номер должен начинаться с +7(ххх) и иметь длинну 11 цифр";
            div.classList.remove("my-popup-done");
            div.classList.add("my-popup-error");
        } else if (data === "bigsize") {
            p.innerHTML = "Ошибка";
            span.innerHTML = "Простите, но размер вашего файла не должен быть больше 10 Мб";
            div.classList.remove("my-popup-done");
            div.classList.add("my-popup-error");
        } else if (data === "format") {
            p.innerHTML = "Неверный формат";
            span.innerHTML = "Формат вашего файла должен быть: .doc, .docx, .pdf, .jpeg, .jpg, .png";
            div.classList.remove("my-popup-done");
            div.classList.add("my-popup-error");
        } else {
            p.innerHTML = "Что то пошло не так";
            span.innerHTML = "Пожалуйста, повторите еще, если ошибка не исчезнет дайте нам знать";
            div.classList.add("my-popup-error");
        }
        //general things for pop-up
        div.classList.add("show-my-wrapper");
        closeBtn.append(document.createElement("i"));
        div.append(p, span, closeBtn);
        document.body.appendChild(div);
    }

    //    Анимация скрола
    function animate({timing, draw, duration}) {
        //Получили время начало анимации (не меняется)
        let start = performance.now();
        requestAnimationFrame(function animate(time) {
            /* time - время которое идет с начала анимации до ее конца (меняется)
             *  То есть начала будет = start*/

            // timeFraction изменяется от 0 до 1
            let timeFraction = (time - start) / duration;
            if (timeFraction > 1) timeFraction = 1;
            // вычисление текущего состояния анимации
            let progress = timing(timeFraction);

            draw(progress); // отрисовать её

            if (timeFraction < 1) {
                requestAnimationFrame(animate);
            }
        });
    }


    // Добавить маски на телефоны
    function addMask() {
        let phones = document.querySelectorAll('input[name=phone]');
        let options = {
            mask: '+{7}(000) 000 00 00',
            lazy: false
        };
        phones.forEach(elem => {
            IMask(elem, options)
        })
    }

    addMask();

    // Открыть / скрыть блок партнеров в футере
    function showPartners() {
        const btn = document.querySelector('.open_partners'),
            box = document.querySelector('.partners');
        btn.addEventListener('click', () => {
            box.classList.toggle('active');
        })
    }showPartners()

});